import moment from "moment";

export const generateAbbreviationName = (name: string): string => {
    let value = "";
    name.split(" ").forEach((item) => {
        value = value + " " + item.charAt(0).toUpperCase();
    });
    return value;
};

export const convertKeyItemOrtherFee = (value: string) => {
    if (!value || typeof value !== "string") return;
    return value.toLowerCase().replaceAll(" ", "_");
};

export const formatCurrency = (value: number): string | null => {
    if (value == -1) return null;
    let val: number = 0;
    return value
        ? value.toLocaleString("en-US", { style: "currency", currency: "USD" })
        : val.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

export const dowloadFile = (
    urlFile: string,
    type = "image/jpeg",
    name = "file"
) => {
    fetch(urlFile, {
        method: "GET",
        headers: {
            "Content-Type": type,
        },
    })
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
            link?.parentNode?.removeChild(link);
        });
};

export const getNameFromLink = (link: string): string => {
    if (!link || typeof link !== "string") return "";
    const splitPath =
        decodeURIComponent(link).split("/")[link.split("/").length - 1];
    const nameFiles = splitPath.split("_");
    nameFiles.shift();
    const nameFile = nameFiles.join("_");
    return nameFile;
};

export const formatDate = (value: any, format: string, valNUll: string) => {
    if (!value) return valNUll;
    return moment(value).format(format);
};

export const getFirstLetterOfName = (name: string) => {
    if (!name || typeof name !== "string") return "";
    const list = name.split(" ");
    return list.map((item) => item.slice(0, 1).toUpperCase()).join("");
};

export const formatCurrency2 = (value: number): string | null => {
    if (value == -1) return null;
    let val: number = 0;
    return value
        ? value.toLocaleString("en-US", {style: "currency", currency: "USD"})
        : val.toLocaleString("en-US", {style: "currency", currency: "USD"});
};

export const getOrdinalString = (n: number): string => {
    if (!Number.isInteger(n)) {
        throw new Error("Input must be an integer");
    }

    const lastTwo = Math.abs(n % 100);
    const lastOne = Math.abs(n % 10);

    if (lastTwo >= 11 && lastTwo <= 13) {
        return `th`;
    }

    switch (lastOne) {
        case 1:
            return `st`;
        case 2:
            return `nd`;
        case 3:
            return `rd`;
        default:
            return `th`;
    }
};
