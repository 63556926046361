import React from "react";
import styled from "styled-components";
import { Avatar } from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 36px;
  width: 100%;
  height: 96px;
  background: #FFFFFF;
  box-shadow: 0px 1px 0px #EFF2F7;
`

export const Menus = styled.div`
  display: flex;
  gap: 64px;

  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #494949;
  }

  a.active {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #55BED2;
  }

  a.active::after {
    content: '';
    width: 80%;
    height: 4px;
    display: block;
    background: #55BED2;
    border-radius: 8px;
    margin: 12px auto 0;
  }
`

export const Logo = styled.img`
  width: 64px;
  height: 42px;
`

export const Account = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover div {
    display: block;
  }
`

export const AccountText = styled.span`
  margin-right: 9px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #101820;
`

export const AccountIcon = styled.img`
    width: 36px;
    height: 36px;
    border-radius: 50%;
`

export const StyledNotification = styled.div`
    min-width: 80px !important;
    display: flex;
    background-color: #fff;
    color: #000;
    justify-content: right;
    align-items: center;
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
        padding: 0;
    }
    .ant-menu-horizontal > .ant-menu-submenu {
        top: 0;
    }
    .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-menu-item-icon {
            width: 30px;
            height: 30px;
        }
    }
    .ant-menu-horizontal {
        min-width: 120px !important;
        align-items: center;
        justify-content: center;
        border-bottom: none;
        & > .ant-menu-submenu::after {
            border-bottom: none;
            position: static;
            right: 0;
            left: 0;
        }
        .ant-menu-submenu-title {
            .ant-menu-title-content {
                position: relative;
                top: -15px;
                right: 10px;
                background-color: #dd2d12;
                padding: 2px 5px;
                color: #fff;
                border-radius: 50%;
                font-weight: 600;
                width: 25px;
                height: 25px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
        .ant-menu-submenu {
            &.not-un-read {
                .ant-menu-submenu-title {
                    .ant-menu-title-content {
                        display: none;
                    }
                }
            }
            &.ant-menu-submenu-active {
                padding-right: 0;
            }
        }
    }
`;

export const MenuPopupBackground = styled.div`
    top: 0;
    right: 34px;
    display: none;
    position: absolute;
    padding-top: 76px;
    z-index: 9;
`;

export const MenuPopup = styled.div`
    top: 0px;
    width: 183px;
    height: 104px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(85, 190, 210, 0.15);
    border-radius: 16px;
    overflow: hidden;
    padding: 0px 0;
`;

export const MenuPopupItem = styled.div`
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px;
    height: 52px;
    transition: all 0.2s;

    &:hover {
        background-color: #f8f8f8;
    }

    span {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #101820;
        margin-left: 20px;
        display: block;
    }
`;
